import React from "react";
import Page from "../components/page";

const Mailto = ({ email, subject = '', body = '', children }) => {
  let params = subject || body ? '?' : '';
  if (subject) params += `subject=${encodeURIComponent(subject)}`;
  if (body) params += `${subject ? '&' : ''}body=${encodeURIComponent(body)}`;

  return <a href={`mailto:${email}${params}`}>{children}</a>;
};

const ContactUsPage = (props) => {
  const pageTitle="Contact Us";
  return (
    <Page  location={props.location.href} pageTitle={pageTitle}>
        <h1>{pageTitle}</h1>
          <p>Help us make this site better.  Send any feedback, suggestions, or ideas to:&nbsp;
          <Mailto email="tradingsolutions@harsip.com" subject={`cbbratings.com feedback`} >
            tradingsolutions@harsip.com
          </Mailto></p>
      </Page>);
  
};

export default ContactUsPage;